import React from 'react'
import Sound from 'react-sound'

class Music extends React.Component {
    render() {
        const tripoloskiEnabled = this.props.tripoloskiEnabled
        const url = tripoloskiEnabled ? "tripoloski.mp3" : "ritualen_02.mp3"
        return (
            <Sound
                url={url}
                playStatus={this.props.play ? Sound.status.PLAYING : Sound.status.PAUSED}
                autoLoad={true}
                loop={true}
                volume={10}
            />
        )
    }
}

export default Music
