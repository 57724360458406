const getComparable = (string) => {
    return string.toLowerCase().replace(/ /g, '')
}

const equals = (value, ...options) => {
    for (const option of options) {
        if (getComparable(value) === getComparable(option)) {
            return true
        }
    }
    return false
}

const USERNAME = 'TheChosenOne1987'
const PASSWORD = 'Frestande Villfarelser'

const username = (value) => equals(value, USERNAME)
const password = (value) => equals(value, PASSWORD)
const question1 = (value) => equals(value, 'Blue', 'blå')
const question2 = (value) => equals(value, '1955', 'år 1955', 'year 1955', '55')
const question3 = (value) => equals(value, 'katten', 'no name', 'unnamed', 'the cat', 'cat', 'kitty', 'har inget namn', 'untitled')

const Correct = {
    username,
    password,
    question1,
    question2,
    question3,
    PASSWORD,
}

export default Correct
