import React from 'react'
import background from './graphics/background.jpg'
import gopniks from './graphics/putin2.jpg'
import { ReactComponent as Rune } from './graphics/rune.svg'
import Sigil from './graphics/sigil.png'

class Background extends React.Component {
    render() {
        const tripoloskiEnabled = this.props.tripoloskiEnabled
        const bg = tripoloskiEnabled ? gopniks : background
        const className = tripoloskiEnabled ? "Background Tripoloski" : "Background"
        return (
            <div
                className={className}
                style={{
                    backgroundImage: "url(" + bg + ")",
                }}
            >
                <Rune className="Rune" />
                <img src={Sigil} className="Sigil" alt="Sigil" />
            </div>
        )
    }
}

export default Background
