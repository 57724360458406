import React from 'react'

class Input extends React.Component {
    render() {
        const { label, value, onChange, correct } = this.props
        const inputId = "id-for-" + label
        
        let className="Input"
        if (correct) {
            className += " Correct"
        }

        return (
            <div className={className}>
                <label htmlFor={inputId} className="Label">{label}</label>
                <input id={inputId} className="Value" value={value} onChange={onChange} type="text" autoComplete="off" />
            </div>
        )
    }
}

export default Input
