import './App.scss'
import React from 'react'
import getCountdown from './getCountdown'

const SOON = Date.now() + 10000

class Countdown extends React.Component {
    state = {
        now: Date.now(),
    }

    componentDidMount() {
        this.interval = setInterval(this.handleTick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    handleTick = () => {
        this.setState({
            now: Date.now(),
        })
    }

    getCountdownRemaining = (target) => {
        //target = 0
        //target = SOON
        //target = 1607706000000
        let remaining = target - this.state.now;

        if (remaining < 1000) {
            remaining = 0
        }

        return remaining
    }

    getCountdownTitle = (remaining) => {
        if (remaining > 0) {
            //return "It has begun!"
            return this.props.title1
            
        } else {
            //return "Stand by for\nThe Revelations"
            return this.props.title2
        }
    }

    render() {
        const target = this.props.target
        const remaining = this.getCountdownRemaining(target)
        const countdownTitle = this.getCountdownTitle(remaining)
        const countdown = getCountdown(remaining)
        const text = countdownTitle + "\n" + countdown

        return (
            <>
                <p className="Countdown">{text}</p>
            </>
        );
    }
}

export default Countdown;
