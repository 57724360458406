import React from 'react'
import Input from './Input'
import Correct from "./Correct"
import { Link } from "@reach/router"

class Forgot extends React.Component {
    handleChange = (event, id) => {
        this.props.setInputValue(id, event.target.value)
    }

    handleChangeQuestion1 = (event) => {
        this.handleChange(event, "inputQuestion1")
    }

    handleChangeQuestion2 = (event) => {
        this.handleChange(event, "inputQuestion2")
    }

    handleChangeQuestion3 = (event) => {
        this.handleChange(event, "inputQuestion3")
    }

    render() {
        const inputQuestion1 = this.props.getInputValue('inputQuestion1')
        const inputQuestion2 = this.props.getInputValue('inputQuestion2')
        const inputQuestion3 = this.props.getInputValue('inputQuestion3')

        const correctQuestion1 = Correct.question1(inputQuestion1)
        const correctQuestion2 = Correct.question2(inputQuestion2)
        const correctQuestion3 = Correct.question3(inputQuestion3)

        const correctAll = correctQuestion1 && correctQuestion2 && correctQuestion3

        return (
            <>
                <Input label="What is the color of your car?" value={inputQuestion1} onChange={this.handleChangeQuestion1} correct={correctQuestion1} />
                <Input label="What year was your father born?" value={inputQuestion2} onChange={this.handleChangeQuestion2} correct={correctQuestion2} />
                <Input label="What is the name of your current pet?" value={inputQuestion3} onChange={this.handleChangeQuestion3} correct={correctQuestion3} />
                <Link to={`/${window.location.search}`}>Back to Login</Link>
                {correctAll && (
                    <p>The Password is<br />"{Correct.PASSWORD}"</p>
                )}
            </>
        )
    }
}

export default Forgot
