import React from 'react'
import { ReactComponent as Svg1 } from './graphics/medal1.svg'
import { ReactComponent as Svg2 } from './graphics/medal8.svg'
import { ReactComponent as Svg3 } from './graphics/medal2.svg'
import { ReactComponent as Svg4 } from './graphics/medal3.svg'
import { ReactComponent as Svg5 } from './graphics/medal4.svg'
import { ReactComponent as Svg6 } from './graphics/medal5.svg'
import { ReactComponent as Svg7 } from './graphics/medal6.svg'
import { ReactComponent as Svg8 } from './graphics/medal7.svg'

const Svgs = [
    Svg1,
    Svg2,
    Svg3,
    Svg4,
    Svg5,
    Svg6,
    Svg7,
    Svg8,
]

class Medals extends React.Component {
    render() {
        const { titles } = this.props;
        return (
            <div className="Medals">
                {titles.map((title, index) => {
                    const Svg = Svgs[index]
                    return (
                        <div className="Medal" key={title} >
                            <Svg className="Image" />
                            <p className="Title">{title}</p>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default Medals
