import React from 'react'
import Input from './Input'
import Correct from "./Correct"
import { Link } from "@reach/router"

class Login extends React.Component {
    handleChange = (event, id) => {
        this.props.setInputValue(id, event.target.value)
    }

    handleChangeUsername = (event) => {
        this.handleChange(event, "inputUsername")
    }

    handleChangePassword = (event) => {
        this.handleChange(event, "inputPassword")
    }

    handleLogin = () => {
        this.props.setRevelationsCompleted()
    }

    render() {
        const inputUsername = this.props.getInputValue('inputUsername')
        const inputPassword = this.props.getInputValue('inputPassword')

        const correctUsername = Correct.username(inputUsername)
        const correctPassword = Correct.password(inputPassword)

        return (
            <>
                <Input label="Enter the Username" value={inputUsername} onChange={this.handleChangeUsername} correct={correctUsername} />
                <Input label="Enter the Password" value={inputPassword} onChange={this.handleChangePassword} correct={correctPassword} />
                {correctUsername && !correctPassword && (
                    <Link to={`/forgot${window.location.search}`}>Forgot Password?</Link>
                )}
                {correctUsername && correctPassword && (
                    <button className="LoginButton" onClick={this.handleLogin}>Login</button>
                )}
            </>
        )
    }
}

export default Login
