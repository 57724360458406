const getRandomChar = () => {
    const chars = "\\/"
    return chars.charAt(Math.floor(Math.random() * chars.length))
}

const getCountdownInsane = () => {
    const days = getRandomChar() + getRandomChar()
    const hours = getRandomChar() + getRandomChar()
    const minutes = getRandomChar() + getRandomChar()
    const seconds = getRandomChar() + getRandomChar()

    return days + ':' + hours + ':' + minutes + ':' + seconds
}

const getCountdownSane = (remaining) => {
    const millisPerDay = 1000 * 60 * 60 * 24
    const days = Math.floor(remaining / millisPerDay)
    remaining -= days * millisPerDay

    const millisPerHour = 1000 * 60 * 60
    const hours = Math.floor(remaining / millisPerHour)
    remaining -= hours * millisPerHour

    const millisPerMinute = 1000 * 60
    const minutes = Math.floor(remaining / millisPerMinute)
    remaining -= minutes * millisPerMinute

    const millisPerSecond = 1000
    const seconds = Math.floor(remaining / millisPerSecond)
    remaining -= seconds * millisPerSecond

    return '' + days + ':' + hours + ':' + minutes + ':' + seconds
}

const getCountdown = (remaining) => {
    if (remaining > 0) {
        return getCountdownSane(remaining)
    } else {
        return getCountdownInsane()
    }
}

export default getCountdown
