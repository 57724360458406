import './App.scss'
import React from 'react'
import Background from './Background'
import Music from './Music'
import Medals from './Medals'
import Login from './Login'
import Forgot from './Forgot'
import Countdown from './Countdown'
import { Router } from "@reach/router"
import LocalStorageAccessor from "./LocalStorageAccessor"
import Sound from 'react-sound'

// const REVELATIONS_COMPLETED = new LocalStorageAccessor("revelationsCompleted", false)
/*
const isRevelationsEnabledQuery = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const revelationsEnabledParam = urlParams.get('revelationsEnabled');
    return revelationsEnabledParam === 'true'
}
if (isRevelationsEnabledQuery()) {
    // Reset the test state
    REVELATIONS_COMPLETED.set(false)
}
*/

const isTripoloskiEnabledQuery = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const revelationsEnabledParam = urlParams.get('tripoloskiEnabled');
    return revelationsEnabledParam === 'true'
}
const tripoloskiEnabled = isTripoloskiEnabledQuery()
console.log('tripoloskiEnabled', tripoloskiEnabled)

class App extends React.Component {
    state = {
        playMusic: false,
        playGong: false,
        //revelationsCompleted: REVELATIONS_COMPLETED.get(),
    }

    handleInteraction = () => {
        this.setState({
            playMusic: true,
        })
    }

    /*
    setRevelationsCompleted = () => {
        const revelationsCompleted = true
        this.setState({
            revelationsCompleted,
        })
        REVELATIONS_COMPLETED.set(revelationsCompleted)
        this.setState({
            playGong: true,
        })
    }
    */

    getInputValue = (id) => {
        const stateValue = this.state[id]
        if (stateValue !== null && stateValue !== undefined) {
            return stateValue
        }

        const localStorageAccessor = new LocalStorageAccessor(id, '')
        return localStorageAccessor.get()
    }

    setInputValue = (id, value) => {
        this.setState({
            [id]: value,
        });

        const localStorageAccessor = new LocalStorageAccessor(id, '')
        localStorageAccessor.set(value)
    }

    /*
    isRevelationsEnabled = () => {
        return true
        //return isRevelationsEnabledQuery()
    }
    */

    render() {
        //const revelationsEnabled = this.isRevelationsEnabled()

        const medalTitles = [
            "The Binding",
            "The Revelations"
        ]
        /*
        if (this.state.revelationsCompleted) {
            medalTitles.push("The Revelations")
        }
        */

        /*
                                <Router>
                            {revelationsEnabled ? (
                                this.state.revelationsCompleted ? (
                                    <>
                                        <Countdown path="/" target={0} title1="unused" title2={"Stand by for\nThe Next Challenge"} />
                                    </>
                                ) : (
                                    <>
                                        <Login path="/" getInputValue={this.getInputValue} setInputValue={this.setInputValue} setRevelationsCompleted={this.setRevelationsCompleted} />
                                        <Forgot path="/forgot" getInputValue={this.getInputValue} setInputValue={this.setInputValue} />
                                    </>
                                )
                            ) : (
                                <>
                                    <Countdown path="/" target={1607706000000} title1="It has begun!" title2={"Stand by for\nThe Revelations"} />
                                </>
                            )}
                        </Router>
        */

        return (
            <div className="App" onClick={this.handleInteraction}>
                <Sound
                    url="gong.mp3"
                    playStatus={this.state.playGong ? Sound.status.PLAYING : Sound.status.PAUSED}
                    autoLoad={true}
                    loop={false}
                    volume={15}
                />
                <Music
                    tripoloskiEnabled={tripoloskiEnabled}
                    play={this.state.playMusic}
                />
                <Background tripoloskiEnabled={tripoloskiEnabled} />
                <div className="Content">
                    <Medals titles={medalTitles} />
                    <div className="ContentCenter">
                        <Router>
                            <Countdown path="/" target={0} title1="unused" title2={"Stand by for\nThe Next Challenge"} />
                        </Router>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
